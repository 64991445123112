import { useStyletron } from "baseui";
import { Card, StyledBody } from "baseui/card";
import { Heading, HeadingLevel } from "baseui/heading";
import React from "react";
import { Paragraph } from "../markdown-components";

export const ErrorCard = (props: { error: any }) => {
  const [_css, $theme] = useStyletron();

  console.error("ErrorCard rendering for:", props.error);
  return (
    <Card overrides={{ Root: { style: { backgroundColor: $theme.colors.negative50, color: $theme.colors.contentSecondary } } }}>
      <StyledBody>
        <HeadingLevel>
          <Heading color={$theme.colors.negative500}>There was an internal error rendering this page -- apologies!</Heading>
          <Paragraph>Please try again by refreshing the page.</Paragraph>
        </HeadingLevel>
      </StyledBody>
    </Card>
  );
};
