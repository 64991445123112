import React from "react";
import { ErrorCard } from "./ErrorCard";

/**
 * Dumb catchall error boundary
 * Error boundaries currently have to be classes.
 */
export class CatchAllErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      error,
    };
  }
  render() {
    if (this.state.hasError) {
      return (
        <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <ErrorCard error={this.state.error} />
        </div>
      );
    }
    return this.props.children;
  }
}
